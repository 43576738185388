var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { tip: _vm.$t("Loading"), spinning: _vm.loading } },
    [
      _c(
        "div",
        { staticClass: "block--code-size" },
        [
          _c("code-size", {
            attrs: {
              size: _vm.size,
              ecLevel: _vm.ecLevel,
              codeStatus: _vm.status
            },
            on: {
              onChangeSize: _vm.changeSize,
              onChangeEcLevel: _vm.changeEcLevel
            }
          })
        ],
        1
      ),
      _c("a-divider", { attrs: { dashed: "" } }),
      _c(
        "div",
        { staticClass: "block--code-list" },
        [
          _c("a-list", {
            attrs: {
              "data-source": _vm.codes,
              grid: { gutter: 16, xs: 1, sm: 1, md: 1, lg: 1 }
            },
            scopedSlots: _vm._u([
              {
                key: "renderItem",
                fn: function(item) {
                  return _c(
                    "a-list-item",
                    {},
                    [
                      _c("code-item", {
                        attrs: { item: item, codeStatus: _vm.status },
                        on: {
                          setName: _vm.setName,
                          remove: _vm.removeCode,
                          setType: _vm.setType,
                          setLanguages: _vm.setLanguages,
                          setPoints: _vm.setPoints
                        }
                      })
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "content-end mt-sm" },
        [
          _c("a-button", { on: { click: _vm.addCode } }, [
            _vm._v(" " + _vm._s(_vm.$t("Add Code")) + " ")
          ])
        ],
        1
      ),
      _c("a-divider"),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16], type: "flex", justify: "center" } },
        [
          _vm.ispublic
            ? _c(
                "a-col",
                { attrs: { xs: 24, lg: 5 } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "button",
                      attrs: { icon: "lock", loading: _vm.publicLoading },
                      on: { click: _vm.handlePrivateCode }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Private")) + " ")]
                  )
                ],
                1
              )
            : _c(
                "a-col",
                { attrs: { xs: 24, lg: 5 } },
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "button",
                      attrs: { icon: "unlock", loading: _vm.publicLoading },
                      on: { click: _vm.handlePublicCode }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Public")) + " ")]
                  )
                ],
                1
              ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 5 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "button",
                  attrs: {
                    disabled: _vm.deActiveButton,
                    type: "dashed",
                    icon: "eye"
                  },
                  on: { click: _vm.previewQRCode }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Show demo")) + " ")]
              )
            ],
            1
          ),
          _vm.status === "DRAFT"
            ? [
                _c(
                  "a-col",
                  { attrs: { xs: 24, lg: 5 } },
                  [
                    _c(
                      "a-button",
                      {
                        staticClass: "button",
                        attrs: {
                          type: "primary",
                          ghost: "",
                          icon: "save",
                          loading: _vm.loading,
                          disabled: _vm.deActiveButton
                        },
                        on: { click: _vm.saveDraft }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Save as draft")) + " ")]
                    )
                  ],
                  1
                ),
                _c(
                  "a-col",
                  { attrs: { xs: 24, lg: 5 } },
                  [
                    !_vm.deActiveButton
                      ? _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "top",
                              title: _vm.$t("Are you sure to generate code?"),
                              "ok-text": _vm.$t("Yes"),
                              "cancel-text": _vm.$t("No")
                            },
                            on: { confirm: _vm.publishCode }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                class: {
                                  blink: true,
                                  blinking: _vm.isDefaultDataChanged
                                },
                                attrs: { type: "primary", loading: _vm.loading }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "15px",
                                    height: "15px",
                                    "margin-right": "5px"
                                  },
                                  attrs: { src: require("@/assets/logo.png") }
                                }),
                                _vm._v(" " + _vm._s(_vm.$t("Generate")) + " ")
                              ]
                            )
                          ],
                          1
                        )
                      : _c(
                          "a-button",
                          {
                            class: {
                              blink: true,
                              blinking: _vm.isDefaultDataChanged
                            },
                            attrs: { type: "primary", disabled: "" }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "15px",
                                height: "15px",
                                "margin-right": "5px"
                              },
                              attrs: { src: require("@/assets/logo.png") }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("Generate")) + " ")
                          ]
                        )
                  ],
                  1
                )
              ]
            : [
                _c(
                  "a-col",
                  { attrs: { xs: 24, lg: 5 } },
                  [
                    !_vm.deActiveButton
                      ? _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              placement: "top",
                              title: _vm.$t(
                                "Are you sure to re-generate code ?"
                              ),
                              "ok-text": _vm.$t("Yes"),
                              "cancel-text": _vm.$t("No")
                            },
                            on: { confirm: _vm.regenerateCode }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                class: {
                                  blink: true,
                                  blinking: _vm.isDefaultDataChanged
                                },
                                attrs: { loading: _vm.loading }
                              },
                              [
                                _c("img", {
                                  staticStyle: {
                                    width: "15px",
                                    height: "15px",
                                    "margin-right": "5px"
                                  },
                                  attrs: { src: require("@/assets/logo.png") }
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("Re-Generate")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _c(
                          "a-button",
                          {
                            class: {
                              blink: true,
                              blinking: _vm.isDefaultDataChanged
                            },
                            attrs: { disabled: "" }
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                width: "15px",
                                height: "15px",
                                "margin-right": "5px"
                              },
                              attrs: { src: require("@/assets/logo.png") }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("Re-Generate")) + " ")
                          ]
                        )
                  ],
                  1
                )
              ]
        ],
        2
      ),
      _vm.visible
        ? _c("add-code-from-mobile", {
            attrs: { visible: _vm.visible, firstTime: false, title: _vm.title },
            on: {
              setPointsImport: _vm.setPointsImport,
              close: _vm.closeAddPoint
            }
          })
        : _vm._e(),
      _c("PreviewQRCode", { ref: "previewQRRef" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }