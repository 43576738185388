export const parseCodes = data => {
  return data.reduce((acc, code, currentIndex) => {
    return acc.concat({
      key: currentIndex, // code.id ? Number(code.id) : currentIndex,
      codeName: `Code #${currentIndex}`, //`Code #${code.id || currentIndex}`,
      naviType: Number(code.navi_type),
      languages: code.name.map(item => item.lang),
      name: parseLangName(code.name),
      points: parsePoints(code.points, code.navi_type)
    });
  }, []);
};

const parsePoints = (points, naviType) => {
  return points.map((point, index) => {
    const namePoint = point.name.reduce((acc, item) => {
      return { ...acc, [item.lang]: item.text };
    }, {});
    const inforPoint = point.information.reduce((acc, item) => {
      return { ...acc, [`INFO_${item.lang}`]: item.text };
    }, {});
    let newPoint = {
      key: index,
      ...namePoint,
      ...inforPoint,
      LAT: point.lat,
      LONG: point.lon
    };
    if (naviType === '0') {
      newPoint = {
        ...newPoint,
        CATEGORIES: point.categories.split('|')
      };
    }
    return newPoint;
  });
};

const parseLangName = name => {
  return name.reduce((acc, item) => {
    return { ...acc, [item.lang]: item.text };
  }, {});
};
