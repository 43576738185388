//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import AddCodeFromMobile from './modals/AddCodeFromMobile';
import CodeSize from '../CodeSize';
import CodeItem from './CodeItem';
import { parseCodes } from './parse-data-client';
import { combindCode } from './parse-data-server';
import { isValidLATLONG } from '@/utils/util';
import PreviewQRCode from './modals/PreviewQRCode.vue';
import { deepDiffMapper } from '@/utils/diff-objects';

export default {
  components: {
    CodeSize,
    CodeItem,
    AddCodeFromMobile,
    PreviewQRCode
  },

  props: {
    title: { type: String, required: true, default: '' },
    code: { type: Object, default: () => ({}) },
    category: { type: Object, default: () => ({}) },
    thumbnail: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      codes: [],
      size: '2',
      ecLevel: '1',
      status: '',
      loading: false,
      visible: false,
      ispublic: false,
      publicLoading: false,
      defaultCode: null
    };
  },
  created() {
    this.initialData();
    this.defaultCode = JSON.parse(
      JSON.stringify({
        title: this.title,
        category: this.category,
        thumbnail: this.thumbnail,
        size: this.size,
        ecLevel: this.ecLevel,
        codes: this.codes
      })
    );
  },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    codeId() {
      return this.$route.params.codeId;
    },
    validateCodeName() {
      return this.codes.some(code => {
        if (code?.languages?.length === 0) return true;
        return code?.languages?.some(lang => code.name[lang] === '');
      });
    },
    validatePoints() {
      return this.codes.some(code => {
        if (code?.points?.length === 0) return true;
        return code?.points?.some(point => {
          if (code.languages.some(lang => !point[lang])) return true;
          if (!isValidLATLONG(point.LAT) || !isValidLATLONG(point.LONG)) return true;
          if (code.naviType === 0 && point.CATEGORIES.length === 0) return true;
        });
      });
    },
    deActiveButton() {
      if (
        !this.title ||
        this.codes?.length === 0 ||
        this.validateCodeName ||
        this.validatePoints ||
        (this.thumbnail?.url && !this.thumbnail?.alt)
      ) {
        return true;
      }
      return false;
    },
    codeType() {
      const naviCodes = this.codes.filter(item => item.naviType === 1);
      const faciCodes = this.codes.filter(item => item.naviType === 0);
      return naviCodes.length > 0 && faciCodes.length > 0
        ? 'mix_code'
        : faciCodes.length > 0
        ? 'spot_code'
        : naviCodes.length > 1
        ? 'voice_code'
        : 'navi_code';
    },
    editedCode() {
      const data = {
        title: this.title,
        category: this.category,
        thumbnail: this.thumbnail,
        size: this.size,
        ecLevel: this.ecLevel,
        codes: this.codes
      };
      return data;
    },
    isDefaultDataChanged() {
      const result = deepDiffMapper.isDiff(this.defaultCode, this.editedCode);
      return !this.deActiveButton && result;
    }
  },
  methods: {
    ...mapActions('code', ['updateCode']),
    initialData() {
      this.codes = [...parseCodes(this.code?.codes)];
      this.size = this.code?.sym_size;
      this.ecLevel = this.code?.sym_ec_level;
      this.status = this.code?.status;
      this.ispublic = this.code?.ispublic;
    },
    addCode() {
      this.visible = true;
    },
    removeCode(key) {
      this.codes = this.codes.filter(item => item.key !== key);
    },
    setLanguages({ codeKey, languages }) {
      const code = this.codes.find(item => item.key === codeKey);
      code.languages = languages;
    },
    setName({ codeKey, key, value }) {
      const code = this.codes.find(item => item.key === codeKey);
      code.name[key] = value;
    },
    setType({ codeKey, value }) {
      const code = this.codes.find(item => item.key === codeKey);
      code.naviType = value;
      code.points = [];
    },
    setPoints({ codeKey, newPoints }) {
      const newCodes = [...this.codes];
      const code = newCodes.find(item => item.key === codeKey);
      code.points = [...newPoints];
      this.codes = [...newCodes];
    },
    changeSize(value) {
      this.size = value;
    },
    changeEcLevel(value) {
      this.ecLevel = value;
    },
    regenerateCode() {
      this.submitForm(this.status);
    },
    publishCode() {
      let statusCode = 'PUBLISHED';
      if (this.status === 'DRAFT') {
        statusCode = 'GENERATE';
      }
      this.submitForm(statusCode);
    },
    saveDraft() {
      const statusCode = 'DRAFT';
      this.submitForm(statusCode);
    },
    async submitForm(statusCode) {
      this.$ls.set('confirm-leave', true);
      const input = {
        status: statusCode,
        codes: combindCode(this.codes),
        title: this.title,
        code_type: this.codeType,
        sym_size: this.size,
        sym_ec_level: this.ecLevel,
        category_id: this.category.id,
        thumbnail: this.thumbnail
      };
      try {
        this.loading = true;
        await this.$s.api.page.editCodeById(this.pageId, this.codeId, input);
        this.notify(statusCode, `${this.$t('Code name')}: ${this.title}`);
        this.$router.back();
      } catch (error) {
        this.$notification.error({
          message: this.$t(error.message.trim())
        });
      } finally {
        this.loading = false;
      }
    },
    addCodeFromMobile() {
      this.$refs.addCode.showModal();
    },
    setPointsImport({ type, points }) {
      if (points.length > 0) {
        let key = this.codes.length;
        const newCodes = points.reduce((accCodes, item) => {
          key++;
          const newPoints = item.reduce((acc, point, index) => {
            const newPoint = Object.assign(
              {
                key: index + 1,
                JPN: point.name,
                LAT: point.lat,
                LONG: point.lon,
                ENG: '',
                KOR: '',
                CHI: '',
                INFO_ENG: '',
                INFO_JPN: '',
                INFO_KOR: '',
                INFO_CHI: '',
                editable: true
              },
              type === 0 ? { CATEGORIES: [] } : {}
            );
            return acc.concat(newPoint);
          }, []);
          const code = {
            key: key,
            naviType: type,
            codeName: `Code #${key}`,
            languages: ['JPN'],
            name: {
              ENG: '',
              JPN: '',
              KOR: '',
              CHI: ''
            },
            points: [...newPoints]
          };
          return accCodes.concat(code);
        }, []);
        this.codes = [...this.codes, ...newCodes];
      } else {
        const key = this.codes.length + 1;
        const item = {
          key: key,
          codeName: `Code #${key}`,
          naviType: type,
          languages: ['JPN'],
          name: {
            ENG: '',
            JPN: '',
            KOR: '',
            CHI: ''
          },
          points: [
            Object.assign(
              {
                key: 0,
                JPN: '',
                LAT: '',
                LONG: '',
                ENG: '',
                KOR: '',
                CHI: '',
                INFO_ENG: '',
                INFO_JPN: '',
                INFO_KOR: '',
                INFO_CHI: '',
                editable: true
              },
              type === 0 ? { CATEGORIES: [] } : {}
            )
          ]
        };
        this.codes = [...this.codes, item];
      }

      this.closeAddPoint();
    },
    closeAddPoint() {
      this.visible = false;
    },
    notify(type = 'DRAFT', description = '') {
      const config = {
        DRAFT: {
          message: this.$t('SaveDraftSuccess'),
          description
        },
        GENERATE: {
          message: this.$t('SaveGenerateSuccess'),
          description
        },
        PUBLISHED: {
          message: this.$t('SaveGenerateSuccess'),
          description
        },
        ABOLISHED: {
          message: this.$t('SaveAbolishedSuccess'),
          description
        },
        PENDING: {
          message: this.$t('SaveGenerateSuccess'),
          description
        }
      };
      return new Promise(resolve => {
        setTimeout(() => {
          this.$notification.success(config[type]);
        }, 100);
        resolve({});
      });
    },
    previewQRCode() {
      const data = {
        codes: combindCode(this.codes),
        sym_size: this.size,
        sym_ec_level: this.ecLevel
      };
      this.$refs.previewQRRef.handlePreview(data);
    },
    handlePublicCode() {
      this.updateCodeById({ ispublic: true, status: this.status });
    },
    handlePrivateCode() {
      this.updateCodeById({ ispublic: false, status: this.status });
    },
    async updateCodeById(input) {
      const successNotifyMessage = {
        true: this.$t('This code is public.'),
        false: this.$t('This code is private.')
      };
      const errorNotifyMessage = {
        true: this.$t('This code public failed.'),
        false: this.$t('This code private failed.')
      };
      try {
        this.publicLoading = true;
        const data = await this.$s.api.page.editCodeById(this.pageId, this.codeId, input);
        this.updateCode(data);
        this.$notification.success({
          message: successNotifyMessage[input.ispublic]
        });
        this.ispublic = input.ispublic;
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: errorNotifyMessage[input.ispublic]
        });
      } finally {
        this.publicLoading = false;
      }
    }
  }
};
